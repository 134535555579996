/* Conteneur principal */
.settings-container {
  position: relative;
  width: 100vw;
  height: 120vh;
  background: linear-gradient(to bottom right, #ff4081, #f0f0f0);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

/* Conteneur pour le formulaire */
.form-wrapper {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

/* Style du titre du formulaire */
.settings-container h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Étiquettes des champs de formulaire */
.settings-container label {
  display: block;
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #555;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
}

/* Champs de formulaire */
.settings-container input[type="number"],
.settings-container input[type="text"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

/* Styles pour le bouton */
.save-button {
  padding: 15px 30px;
  background-color: #ff4081;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
  width: 100%;
}

/* Couleur au survol du bouton */
.save-button:hover {
  background-color: #e0005d;
  transform: translateY(-3px);
}

/* Couleur au clic du bouton */
.save-button:active {
  background-color: #c0004a;
  transform: translateY(0);
}

/* Messages d'erreur */
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 1px;
  text-align: center;
  width: 100%;
}

/* Conteneur pour les entrées d'équipe */
.team-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  gap: 10px;
}

.team-input label {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.team-input input[type="text"] {
  width: 100%;
}

.score-input {
  width: 60px;
  text-align: center;
}

/* Style de l'onglet Gift List */
.gift-list-tab {
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-radius: 10px;
  width: 100%;
  text-align: left;
  transition: background-color 0.3s ease, padding 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre douce autour de l'onglet */
  cursor: default;
}

/* Onglet Gift List ouvert */
.gift-list-tab.open {
  background-color: #e0e0e0;
  padding: 15px 20px; /* Augmente la taille du padding quand ouvert */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Ombre renforcée quand l'onglet est ouvert */
}

/* Animation pour le contenu */
.gift-list-content {
  margin-top: 10px;
  padding: 10px;
  font-size: 1rem;
  color: #555;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s ease, opacity 0.4s ease, padding 0.3s ease;
  border-radius: 10px;
  background-color: #f9f9f9; /* Fond clair pour le contenu */
}

/* Onglet ouvert - animation d'extension */
.gift-list-tab.open .gift-list-content {
  max-height: 300px; /* Max-height augmentée pour plus de flexibilité */
  opacity: 1;
  padding-top: 15px; /* Espace supplémentaire quand l'onglet est ouvert */
}

/* Grille pour les icônes de cadeaux */
.gift-icons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); /* Utilise une grille flexible */
  gap: 15px;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto; /* Permet le défilement vertical si le contenu dépasse la hauteur */
}

/* Style des éléments de la liste des cadeaux */
.gift-item {
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gift-item:hover {
  transform: scale(1.05); /* Légère augmentation de taille au survol */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Ombre plus marquée au survol */
}

/* Icônes de cadeau */
.gift-item img {
  border-radius: 10px;
  max-width: 60px;
  max-height: 60px;
  transition: transform 0.3s ease;
}

.gift-item img:hover {
  transform: scale(1.1); /* Agrandissement au survol des icônes */
}

.gift-item p {
  margin-top: 5px;
  font-size: 1rem;
  color: #555;
  text-align: center;
}

/* Animation pour les icônes et texte */
.gift-icons .gift-item {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards; /* Animation d'apparition */
}

.gift-icons .gift-item:nth-child(odd) {
  animation-delay: 0.2s;
}

.gift-icons .gift-item:nth-child(even) {
  animation-delay: 0.4s;
}

/* Styles pour la barre de recherche */
.search-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.search-input {
  width: 80%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.checkbox-container {
  display: flex;
  gap: 10px; /* Espace entre les cases */
}

.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px; 
  height: 25px; 
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  border: 2px solid #ddd; 
}

.checkbox-label input {
  display: none;
}

.checkbox-label.blue {
  background-color: #007bff;
  border-color: #007bff;
}

.checkbox-label.red {
  background-color: #ff073a;
  border-color: #ff073a;
}

.checkbox-label input:checked + .checkmark {
  color: white;
  font-size: 18px; /* Taille de la croix plus grande */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-label input:checked + .checkmark:before {
  content: "✖"; /* Affiche une croix quand la case est cochée */
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Adaptabilité mobile */
@media (max-width: 768px) {
  .settings-container {
    padding: 10px;
  }

  .form-wrapper {
    padding: 20px;
    box-sizing: border-box;
  }

  .team-input {
    flex-direction: column;
  }

  .save-button {
    font-size: 0.9rem;
  }

  /* Ajuste la taille du texte et des champs pour les petits écrans */
  .settings-container h1 {
    font-size: 1.5rem;
  }

  .settings-container label {
    font-size: 1rem;
  }

  .settings-container input[type="number"],
  .settings-container input[type="text"],
  .score-input {
    font-size: 0.9rem;
    padding: 8px;
  }

  .score-input {
    width: 100%; /* Ajuste le score pour qu'il prenne toute la largeur sur mobile */
    text-align: left;
  }

  .gift-icons {
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr)); /* Ajustement pour petits écrans */
  }

  .gift-item img {
    max-width: 50px; /* Taille des images réduite sur mobile */
    max-height: 50px;
  }
}

/* Adaptabilité pour très petits écrans */
@media (max-width: 480px) {
  .settings-container h1 {
    font-size: 1.3rem;
  }

  .save-button {
    font-size: 0.8rem;
    padding: 10px 20px;
  }
}
