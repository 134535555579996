/* Conteneur principal */
.home-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom right, #ff4081, #f0f0f0); /* Couleur d'arrière-plan dégradée */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Centre le texte horizontalement */
}

/* Conteneur pour l'image */
.image-wrapper {
  margin-bottom: 50px; /* Réduit l'espacement en dessous de l'image */
}

.live-icon {
  width: 250px; /* Largeur de l'image */
  height: auto; /* Maintient les proportions de l'image */
}

/* Conteneur pour le texte */
.text-wrapper {
  margin-bottom: 20px; /* Espacement en dessous du texte */
}

/* Style du titre */
.title-text {
  font-size: 2.5rem;
  color: white;
  margin-top: 10px; /* Réduit l'espacement au-dessus du titre pour rapprocher le titre de l'image */
  margin-bottom: 20px; /* Espacement en dessous du titre */
}

/* Style du texte d'explication */
.explanatory-text {
  font-size: 1.2rem;
  color: white;
  max-width: 600px; /* Largeur maximale du texte d'explication */
  margin: 0 auto 50px auto; /* Centre le texte horizontalement et espace en bas */
}

/* Conteneur pour les boutons (flexbox pour un alignement vertical) */
.buttons-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Espacement entre les boutons */
}

/* Conteneur individuel pour chaque bouton */
.button-container {
  display: flex;
  justify-content: center;
}

/* Styles pour les boutons */
.navigate-button {
  padding: 15px 30px;
  background-color: #ff4081;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2); /* Ombre pour l'effet de profondeur */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.navigate-button:hover {
  background-color: #e0005d; /* Couleur au survol */
  transform: translateY(-3px); /* Légère translation au survol */
}

.navigate-button:active {
  background-color: #c0004a; /* Couleur au clic */
  transform: translateY(0); /* Remet à sa place après le clic */
}

/* Animation de glissement vers l'avant depuis un peu plus bas */
.slide-fwd-bottom {
  -webkit-animation: slide-fwd-bottom 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-fwd-bottom 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-fwd-bottom {
  0% {
    transform: translateZ(-1400px) translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@keyframes slide-fwd-bottom {
  0% {
    transform: translateZ(-1400px) translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/* Texte "demo v2" en bas à droite */
.demo-text {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 1.2rem;
  color: rgb(199, 194, 194);
  opacity: 0.7; /* Légère transparence pour un effet subtil */
}
