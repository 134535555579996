/* Conteneur principal */
.credit-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom right, #ff4081, #f0f0f0); /* Arrière-plan dégradé */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 0px; /* Espace en haut pour l'image */
  }
  
  /* Style du logo */
  .logo-wrapper {
    margin-bottom: 0px; /* Espacement sous le logo */
  }
  
  .logo-image {
    max-width: 200px; /* Taille maximale du logo */
    height: auto; /* Garder les proportions */
  }
  
  /* Conteneur pour le texte */
  .text-wrapper {
    margin-bottom: 20px; /* Espacement en dessous du texte */
  }
  
  /* Style du titre */
  .title-text {
    font-size: 2.5rem;
    color: white;
    margin-top: 10px; /* Réduit l'espacement au-dessus du titre */
    margin-bottom: 20px; /* Espacement en dessous du titre */
  }
  
  /* Style du texte d'explication */
  .explanatory-text {
    font-size: 1.2rem;
    color: white;
    max-width: 600px; /* Largeur maximale du texte */
    margin: 0 auto 20px auto; /* Centre le texte horizontalement et espace en bas */
  }
  
  /* Liste des crédits */
  .credits-list {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    max-width: 600px; /* Limite la largeur de la liste */
    text-align: center; /* Centrer le texte */
    color: white;
    font-size: 1.2rem;
  }
  
  /* Éléments de la liste des crédits */
  .credits-list li {
    margin-bottom: 10px; /* Espacement entre les éléments de la liste */
  }
  
  /* Conteneur pour les boutons */
  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Espacement entre les boutons */
  }
  
  /* Conteneur individuel pour chaque bouton */
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  /* Styles pour les boutons */
  .navigate-button {
    padding: 15px 30px;
    background-color: #ff4081;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2); /* Ombre pour l'effet de profondeur */
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .navigate-button:hover {
    background-color: #e0005d; /* Couleur au survol */
    transform: translateY(-3px); /* Légère translation au survol */
  }
  
  .navigate-button:active {
    background-color: #c0004a; /* Couleur au clic */
    transform: translateY(0); /* Remet à sa place après le clic */
  }

  /* Animation de glissement vers l'avant depuis un peu plus bas */
.slide-fwd-bottom {
    -webkit-animation: slide-fwd-bottom 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-fwd-bottom 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  
  @-webkit-keyframes slide-fwd-bottom {
    0% {
      transform: translateZ(-1400px) translateY(200px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-fwd-bottom {
    0% {
      transform: translateZ(-1400px) translateY(200px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  