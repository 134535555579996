/* Conteneur général */
/* Conteneur général */
.container {
  width: 56.25vh;
  height: 100vh;
  max-width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr auto; /* Ajoutez une ligne supplémentaire pour le carrousel */
  background: linear-gradient(to right, #0000FF 50%, #FF0000 50%);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}


/* Colonnes - Phunk et Techno */
.side {
  padding: 2vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Contenu aligné en haut */
  position: relative;
  height: 100%; /* Occupe toute la hauteur de l'écran */
  transition: transform 0.3s ease;
}

.side h1 {
  margin: 0;
  padding-top: 2vh;
  font-size: clamp(1.5rem, 4vw, 2rem);
}

.phunk.top, .techno.top {
  margin-top: 20%;
}

.middle {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Jauge */
.jauge-container {
  display: flex;
  align-items: center;
  width: 90%;
  top: 5%;
  height: clamp(5vh, 8vh, 10vh); /* Hauteur flexible avec des limites */
  background-color: #ccc;
  border-radius: 35px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin-top: 2vh;
}

.jauge {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: clamp(1rem, 2vw, 1.5rem);
}

.jauge.phunk {
  background-color: #2778f1;
}

.jauge.techno {
  background-color: #ee7587;
}

.percentage-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
}

/* Icônes sous la jauge */
.icons-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Espacement entre les icônes */
  margin-top: 10vh; /* Augmenter la marge pour les pousser plus bas */
  width: 100%;
  flex-grow: 1; /* Pousse les icônes vers le bas */
}

.icon {
  width: clamp(2rem, 6vw, 5rem);
  height: auto;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.icon:hover {
  transform: scale(1.1); /* Effet de survol des icônes */
}

/* Boutons */
.settings-button, .home-button {
  padding: 1vh 2vw;
  background-color: #ff408000;
  color: white;
  border: 2px solid black;
  border-radius: 20px;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  font-weight: bold;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.settings-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.home-button {
  position: absolute;
  top: 10px;
  left: 10px; /* Place le bouton à 10px du bord gauche */
}

.container:hover .settings-button,
.container:hover .home-button {
  opacity: 1;
}

.countdown-timer {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  font-size: clamp(2rem, 3vw, 2.5rem);  /* Augmentation de la taille minimale et maximale */
  font-weight: bold;
  z-index: 1000;
  color: white;
}

.blinking {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 50% { color: #ee7587; }
  100% { color: white; }
}

/* Media Queries pour écrans plus petits */
@media (max-width: 768px) {
  .jauge-container {
    width: 85%;
  }
  .actions {
    padding: 1.5vh;
  }
  .action-item, .jauge, .percentage-label, .countdown-timer {
    font-size: clamp(1rem, 2.5vw, 1.5rem);
  }
  .icon {
    width: clamp(2rem, 6vw, 5rem);
  }
}

/* Media Queries pour très petits écrans */
@media (max-width: 480px) {
  .jauge-container {
    width: 80%;
  }
  .action-item, .jauge, .percentage-label, .countdown-timer {
    font-size: clamp(0.8rem, 3vw, 1rem);
  }
  .icon {
    width: clamp(1.5rem, 8vw, 6rem);
  }
}

/* Carrousel */
.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Assurez-vous que le contenu est centré dans le carrousel */
  height: 150%; /* Laisser le carrousel occuper toute la hauteur de la ligne */
  position: relative; /* Nécessaire pour positionner la boîte de manière absolue */
}

/* Conteneur pour les images du carrousel */
.carousel-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 images par ligne */
  gap: 10px;
  width: 80%;
  position: relative; /* Nécessaire pour positionner la boîte de manière absolue */
}

.carousel-images img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}