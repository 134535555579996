:root {
  --text-color-phunk: white;
  --text-color-techno: white;
}

html, body {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #0000ffbb 50%, #e26868 50%);
}

.container {
  width: 56.25vh; /* Ratio 9:16 */
  height: 100vh;
  max-width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr; /* Ajustement pour permettre la flexibilité des lignes */
  background: linear-gradient(to right, #0000FF 50%, #FF0000 50%);
  box-sizing: border-box;
  overflow: hidden;
  position: relative; /* Permet le placement absolu des éléments */
}

.side {
  padding: 20px;
  color: var(--text-color-phunk);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative; /* Permet aux éléments positionnés absolument d'être positionnés par rapport à .side */
  transition: transform 0.3s ease; /* Pour un déplacement fluide */
}

.phunk.top {
  grid-column: 1;
  grid-row: 1;
}

.phunk.bottom {
  grid-column: 1;
  grid-row: 2;
}

.techno.top {
  grid-column: 2;
  grid-row: 1;
}

.techno.bottom {
  grid-column: 2;
  grid-row: 2;
}

.middle {
  grid-column: 1 / span 2; /* Occupe les deux colonnes */
  grid-row: 1 / span 2; /* Occupe les deux lignes */
  position: absolute; /* Positionné relativement à .container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* Ignore les événements de souris */
}

.jauge-container {
  display: flex;
  align-items: center;
  width: 93%; /* Augmente la largeur à 100% de la zone disponible */
  position: relative;
  margin-top: 0;
  margin-bottom: 50px;
  height: 100px; /* Augmente la hauteur de la jauge */
  background-color: #ccc;
  border-radius: 35px; /* Accentue les coins arrondis */
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.jauge {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2em;
}

/* Jauge bleue (gauche) */
.jauge.phunk {
  background-color: #2778f1;
}

/* Jauge rouge (droite) */
.jauge.techno {
  background-color: #ee7587;
}

.percentage-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-color-phunk);
  font-size: 1.5em;
  font-weight: bold;
}

.percentage-label.left {
  left: 10px; /* Positionné à gauche avec un léger décalage */
}

.percentage-label.right {
  right: 10px; /* Positionné à droite avec un léger décalage */
}

.jauge-percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: black;
}

.or-text {
  font-size: 1.3em;
  color: var(--text-color-phunk);
  text-align: center;
  margin: 0;
  position: absolute;
  top: 53px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  z-index: 1;
}

.actions {
  display: flex;
  margin-top: 500px;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2); /* Ajout d'un fond translucide */
  border-radius: 20px; /* Coins arrondis du conteneur des actions */
  padding: 20px; /* Espace intérieur pour éviter que les éléments touchent les bords */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ajout d'une ombre pour l'effet de profondeur */
}

.action-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5em; /* Augmenter la taille du texte ici */
}

.icon {
  width: 40px;
  margin-right: 10px;
}

.pseudo {
  font-size: 1.3em;
  color: black;
}

.gift-container {
  background-color: rgba(255, 255, 255, 0.2); /* Ajout d'un fond translucide */
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ajout d'une ombre pour l'effet de profondeur */
  padding: 15px;
  text-align: center;
}

.back-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  background-color: #ff408000;
  color: white;
  border: 2px solid black; /* Ajoute une bordure noire de 2px */
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1000; /* Assurez-vous qu'il est au-dessus des autres éléments */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease, border-color 0.3s ease;
  opacity: 0; /* Masquer le bouton par défaut */
}

/* Afficher le bouton au survol du conteneur */
.container:hover .back-button {
  opacity: 1; /* Rendre le bouton visible au survol */
}

/* Optionnel : Ajouter un effet de survol pour la bordure */
.back-button:hover {
  border-color: #ff4081; /* Change la couleur de la bordure au survol */
}

.settings-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  background-color: #ff408000;
  color: white;
  border: 2px solid black; /* Ajoute une bordure noire de 2px */
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1000; /* Assurez-vous qu'il est au-dessus des autres éléments */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease, border-color 0.3s ease;
  opacity: 0; /* Masquer le bouton par défaut */
}

/* Afficher le bouton au survol du conteneur */
.container:hover .settings-button {
  opacity: 1; /* Rendre le bouton visible au survol */
}

/* Optionnel : Ajouter un effet de survol pour la bordure */
.settings-button:hover {
  border-color: #ff4081; /* Change la couleur de la bordure au survol */
}

.draggable {
  position: absolute;
  cursor: grab;
  user-select: none;
}

.draggable:active {
  cursor: grabbing;
}

.resize-handle {
  position: absolute;
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.5);
  cursor: nwse-resize;
  z-index: 1000;
  display: none; /* Cacher les poignées de redimensionnement par défaut */
}

.draggable:hover .resize-handle {
  display: block; /* Afficher les poignées lors du survol */
}

.resize-handle.bottom-right {
  right: 0;
  bottom: 0;
}

/* Container for color picker controls */
.color-picker {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.color-picker label {
  display: block;
  margin-bottom: 10px;
}
