/* Conteneur principal */
.retour-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom right, #ff4081, #f0f0f0);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
  
  /* Conteneur pour l'image */
  .image-wrapper {
    transform: scale(0.6);
    margin-bottom: 0px;
  }
  
  .slide-fwd-bottom {
    -webkit-animation: slide-fwd-bottom 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-fwd-bottom 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  
  @-webkit-keyframes slide-fwd-bottom {
    0% {
      transform: translateZ(-1400px) translateY(200px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-fwd-bottom {
    0% {
      transform: translateZ(-1400px) translateY(200px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  
  /* Style pour le texte */
  .title-text {
    font-size: 2.5rem;
    color: white;
    margin-bottom: 0px;
  }
  
  .explanatory-text {
    font-size: 1.2rem;
    color: white;
    max-width: 600px;
    margin: 0 auto 30px auto;
  }
  
  /* Champ d'entrée de texte */
  .input-container {
    margin-bottom: 20px;
  }
  
  .input-message {
    padding: 15px;
    font-size: 1.1rem;
    width: 300px;
    border-radius: 10px;
    border: none;
  }
  
  /* Système d'évaluation par étoiles */
  .rating-wrapper {
    margin-bottom: 20px;
  }
  
  .star {
    font-size: 3rem;
    cursor: pointer;
    color: #ccc; /* Couleur des étoiles non sélectionnées */
    transition: color 0.2s;
  }
  
  .star.filled {
    color: #ffdd57; /* Couleur des étoiles sélectionnées */
  }
  
  /* Style pour le message de confirmation */
  .confirmation-text {
    font-size: 1.2rem;
    color:  #ffffff;
    margin-bottom: 20px;
  }
  
  /* Boutons */
  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .send-button, .navigate-button {
    padding: 15px 30px;
    background-color: #ff4081;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .send-button:hover, .navigate-button:hover {
    background-color: #e0005d;
    transform: translateY(-3px);
  }
  
  .send-button:active, .navigate-button:active {
    background-color: #c0004a;
    transform: translateY(0);
  }
  